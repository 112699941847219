import {
    SELECTED_EMPLOYEE,
    BANK_INFORMATION,
    HIRING_INFORMATION,
    PERSONAL_INFORMATION,
    RESET_EMPLOYEE,
    VARIATION_FILE, VARIATION_INFORMATION
} from "../../constants/ActionTypes";


export const setPersonalInformation = (data) => {
    return {
        type: PERSONAL_INFORMATION,
        payload: data
    };
}

export const setHiringInformation = (data) => {
    return {
        type: HIRING_INFORMATION,
        payload: data
    };
}

export const setBankingInformation = (data) => {
    return {
        type: BANK_INFORMATION,
        payload: data
    };
}


export const setVariationFile = (data) => {
    return {
        type: VARIATION_FILE,
        payload: data
    };
}

export const setVariationInformation = (data) => {
    return {
        type: VARIATION_INFORMATION,
        payload: data
    };
}

export const setSelectedEmployee = (data) => {
    return {
        type: SELECTED_EMPLOYEE,
        payload: data
    };
}

export const resetEmployeeInformation = () => {
    return {
        type: RESET_EMPLOYEE,
    };
}



