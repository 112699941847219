import {
    PAYROLL_INFORMATION,
    PAYROLL_ITEMS,
    PAYROLL_PERIOD,
    PENDING_PAYMENT,
    RESET_PAYROLL
} from "../../constants/ActionTypes";

export const setPayrollInformation = (data) => {
    return {
        type: PAYROLL_INFORMATION,
        payload: data
    };
}

export const setPayrollPeriod = (data) => {
    return {
        type: PAYROLL_PERIOD,
        payload: data
    };
}

export const setPendingPayment = (data) => {
    return {
        type: PENDING_PAYMENT,
        payload: data
    };
}

export const setProcessingItems = (data) => {
    return {
        type: PAYROLL_ITEMS,
        payload: data
    };
}

export const resetPayrollProcesses = () => {
    return {
        type: RESET_PAYROLL,

    };
}
