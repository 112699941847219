import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Button, Dropdown, Menu, Popover} from "antd";
import {openCompanyDrawer, userSignOut} from "../../appRedux/actions";
import {DownOutlined, ShopFilled, PoweroffOutlined} from "@ant-design/icons";
import {FaUser} from "react-icons/fa";

const UserProfile = () => {
    const dispatch = useDispatch();
    const {authUser} = useSelector(({auth}) => auth);


    const menu = (
        <Menu className="bg-blue-300 bg-opacity-75  pt-6 pl-1">
            <Menu.Item key="0">
               <div>
                   <ShopFilled className="text-white text-lg"/>
                   <span className="pl-10 align-middle text-white font-semibold" onClick={()=>{
                       dispatch(openCompanyDrawer())
                   }}>Select Company</span>
               </div>

            </Menu.Item>

            <Menu.Divider/>
            <Menu.Item key="1">
                <div className="cursor-pointer" onClick={()=>{
                    dispatch(userSignOut());
                }}>
                    <PoweroffOutlined  className="text-white text-lg"/>

                    <span className="pl-10 align-middle text-white font-semibold">Sign out</span>
                </div>

            </Menu.Item>
        </Menu>
    );

    return (


        <Dropdown trigger="click" overlay={menu} className="">
            <Button
                className="focus:bg-blue-200 focus:bg-opacity-70 rounded w-full py-auto h-11 flex bg-opacity-70 bg-blue-200 hover:bg-blue-200  border-blue-200 hover:border-blue-200"
                size="large">
                <div className="rounded-full bg-white text-blue-400 p-1 text-xl">
                    <FaUser/>

                </div>
                <span
                    className="mx-auto text-white font-semibold">{authUser ? authUser.first_name.toUpperCase() : ''}</span>
                <div className="align-middle text-xs text-white"><DownOutlined/></div>
            </Button>
        </Dropdown>


    )
};

export default UserProfile;
