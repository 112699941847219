import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">
        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>


            <Route path={`${match.url}company/pay-grades`} component={asyncComponent(() => import('./PayGrades'))}/>
            <Route path={`${match.url}company/new-grade`}
                   component={asyncComponent(() => import('./PayGrades/AddGrade'))}/>
            <Route path={`${match.url}company/edit-grade/:slug`}
                   component={asyncComponent(() => import('./PayGrades/ModifyPayGrade'))}/>

            <Route path={`${match.url}company/apply-grade/:slug`}
                   component={asyncComponent(() => import('./PayGrades/AssignGrade'))}/>

            <Route path={`${match.url}company/rates`}
                   component={asyncComponent(() => import('./CompanyRates'))}/>
            <Route path={`${match.url}employee/employee-variations`}
                   component={asyncComponent(() => import('./EmployeeVariations'))}/>
            <Route path={`${match.url}employee/employee-center`}
                   component={asyncComponent(() => import('./EmployeeCenter'))}/>
            <Route path={`${match.url}employee/employee-rates`}
                   component={asyncComponent(() => import('./EmployeePayRates'))}/>
            <Route path={`${match.url}employee/employee-earnings`}
                   component={asyncComponent(() => import('./EmployeeEarnings'))}/>
            <Route path={`${match.url}employee/employee-grades`}
                   component={asyncComponent(() => import('./EmployeeGrades'))}/>
            <Route path={`${match.url}employee/new-employee`}
                   component={asyncComponent(() => import('./EmployeeSetup/NewEmployee'))}/>
            <Route path={`${match.url}employee/modify/:slug`}
                   component={asyncComponent(() => import('./EmployeeModification'))}/>
            <Route path={`${match.url}employee/employee-import-wizard`}
                   component={asyncComponent(() => import('./EmployeeSetup/EmployeeImportWizard'))}/>
            <Route path={`${match.url}employee/employee-variations`}
                   component={asyncComponent(() => import('./EmployeeVariations'))}/>
            <Route path={`${match.url}employee/variation-wizard`}
                   component={asyncComponent(() => import('./EmployeeVariationWizard'))}/>

            <Route path={`${match.url}payroll/payroll-center`}
                   component={asyncComponent(() => import('./PayrollCenter'))}/>

            <Route path={`${match.url}payroll/employee-exclusions/:payrollId`}
                   component={asyncComponent(() => import('./EmployeeExclusions'))}/>

            <Route path={`${match.url}payroll/deleted-earnings/:payrollId`}
                   component={asyncComponent(() => import('./DeletedEarnings'))}/>
            <Route path={`${match.url}payroll/new-payroll`}
                   component={asyncComponent(() => import('./PayrollProcess/NewPayroll'))}/>
            <Route path={`${match.url}payroll/payroll-items`}
                   component={asyncComponent(() => import('./PayrollItems'))}/>
            <Route path={`${match.url}payroll/paychecks`} component={asyncComponent(() => import('./Paychecks'))}/>
            <Route path={`${match.url}payroll/employee-paychecks/:id`}
                   component={asyncComponent(() => import('./Paychecks/EmployeePaychecks'))}/>
            <Route path={`${match.url}payroll/payroll-register/:id`}
                   component={asyncComponent(() => import('./PayrollCenter/PayrollRegister'))}/>
            <Route path={`${match.url}payroll/payroll-setup`}
                   component={asyncComponent(() => import('./PayrollSetup'))}/>
            <Route path={`${match.url}payroll/payroll-reports`}
                   component={asyncComponent(() => import('./PayrollReports'))}/>

            <Route path={`${match.url}payroll/legacy-reports`}
                   component={asyncComponent(() => import('./Reports'))}/>
            <Route path={`${match.url}payroll/payroll-warehousing`}
                   component={asyncComponent(() => import('./PayrollWarehousing'))}/>
        </Switch>
    </div>
);

export default App;
