import React, { useState} from "react";


import App from "../../routes/index";


import { useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Layout} from "antd";
import {footerText} from "../../util/config";
import Sidebar from "../Sidebar";

import {BellFilled, CaretDownFilled} from "@ant-design/icons";
import CompanyDrawer from "../../components/CompanyDrawer";
import {openCompanyDrawer} from "../../appRedux/actions";
import {DOCUMENT_BASE_URL} from "../../constants/ServerUrl";
import AlertNotification from "../Alert";
const alert = "😊 Hey Champ!, Great work so far. Just a friendly reminder to checkout before leaving. Cheers! 👍"

const {Content,  Header, Footer} = Layout;
const MainApp = () => {
    let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const {activeCompany} = useSelector(({company}) => company);

    return (
        <Layout className="">
            <Sidebar sidebarCollapsed={sidebarCollapsed}/>
            
            <Layout>
                <AlertNotification message={alert} />
                <Header className="bg-white p-0 h-20">
                    {/*<div className="px-3 py-5 text-2xl cursor-pointer " onClick={() => {*/}
                    {/*    setSidebarCollapsed(!sidebarCollapsed);*/}
                    {/*}}>*/}

                    {/*    {sidebarCollapsed ? (<MenuUnfoldOutlined/>) : (<MenuFoldOutlined/>)}*/}

                    {/*</div>*/}
                    {activeCompany && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>
                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>

                            {activeCompany.company_logo_path && (
                                <div className="flex cursor-pointer" onClick={() => {
                                    dispatch(openCompanyDrawer());
                                }}>
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt={activeCompany.business_name}
                                             src={`${DOCUMENT_BASE_URL}/${activeCompany.company_logo_path}`}/>
                                    </div>
                                    <div>

                                        <p className="pl-3 font-semibold">{activeCompany.business_name} <CaretDownFilled
                                            className="pl-4"/></p>
                                    </div>
                                </div>
                            )}
                            {!activeCompany.company_logo_path && (
                                <div className="flex cursor-pointer " onClick={() => {
                                    dispatch(openCompanyDrawer());
                                }}>
                                    <div className=" rounded-full  p-1 ">

                                        <img width="50" className="rounded-full" alt="POSNL"
                                             src="/images/app-icons/placeholder.jpg"/>
                                    </div>
                                    <div>

                                        <p className=" pl-3 font-semibold">{activeCompany.business_name}
                                            <CaretDownFilled
                                                className=" pl-4"/></p>
                                    </div>
                                </div>)}
                        </div>)}
                    {!activeCompany && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>

                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>
                            <div className="flex cursor-pointer " onClick={() => {
                                dispatch(openCompanyDrawer());
                            }}>
                                <div className=" rounded-full  p-1 ">

                                    <img width="50" className="rounded-full" alt="Phillips Outsourcing"
                                         src="/images/app-icons/placeholder.jpg"/>
                                </div>
                                <div>

                                    <p className=" pl-3 font-semibold">Select a Company<CaretDownFilled
                                        className=" pl-4"/></p>
                                </div>
                            </div>


                        </div>

                    )}
                </Header>
                <Content className="overflow-auto h-screen">
                    <CompanyDrawer/>
                    <App match={match}/>
                    <Footer>
                        <div className="gx-layout-footer-content">
                            {footerText}
                        </div>
                    </Footer>
                </Content>

            </Layout>
        </Layout>
    )
};
export default MainApp;

