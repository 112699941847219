import {
    BANK_INFORMATION,
    HIRING_INFORMATION,
    PERSONAL_INFORMATION,
    RESET_EMPLOYEE, SELECTED_EMPLOYEE, VARIATION_FILE, VARIATION_INFORMATION
} from "../../constants/ActionTypes";

const INIT_STATE = {
    personalInformation: null,
    hiringInformation: null,
    bankInformation: null,
    variationFile: null,
    variationInformation: null,
    selectedEmployee: null,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PERSONAL_INFORMATION: {
            return {
                ...state,
                personalInformation: action.payload,
            }
        }

        case HIRING_INFORMATION: {
            return {
                ...state,
                hiringInformation: action.payload,
            }
        }
        case BANK_INFORMATION: {
            return {
                ...state,
                bankInformation: action.payload,
            }
        }
        case VARIATION_FILE: {
            return {
                ...state,
                variationFile: action.payload,
            }
        }
        case RESET_EMPLOYEE: {
            return {
                ...state,
                personalInformation: null,
                hiringInformation: null,
                bankInformation: null,
            }
        }

        case VARIATION_INFORMATION: {
            return {
                ...state,
                variationInformation: action.payload,
            }
        }
        case SELECTED_EMPLOYEE: {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        }

        default:
            return state;
    }
}
