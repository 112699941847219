import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {HomeFilled, UploadOutlined, UserOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {FaBook, FaUsers, FaBuilding} from "react-icons/fa";
import Helpers from "../../util/Helpers";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    const {authorizations} = useSelector(({auth}) => auth);

    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>


            <div className="flex items-center justify-center py-2 px-2">
                <UserProfile/>
                {/*<AppsNavigation/>*/}
            </div>


            <Menu
                className="bg-blue text-white text-center text-base font-medium my-5"
                mode="inline"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>
                {Helpers.authorizeMe(authorizations[1], 'view', 1) && (

                    <MenuItemGroup key="dashboard" className="mb-5">

                        <Menu.Item key="home">
                            <Link to="/home" className="flex">
                                <div className="text-2xl">
                                    <HomeFilled/>
                                </div>
                                <span className="mx-4 py-1">Home</span>
                            </Link>
                        </Menu.Item>

                    </MenuItemGroup>)}

                <MenuItemGroup key="payroll" className="gx-menu-group"
                               title={<div className="px-3 text-left text-white">PAYROLL</div>}>
                    {Helpers.authorizeMe(authorizations[1], 'view', 2) && (
                        <SubMenu key="company"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaBuilding/>
                                     </div>
                                     <div className="px-6 -my-3">Company</div>
                                 </div>}>
                            {Helpers.authorizeMe(authorizations[1], 'view', 2) && (
                                <Menu.Item key="company/pay-grades">
                                    <Link to="/company/pay-grades">
                                        <span className="pl-7 text-gray-200 text-sm">Pay Grades</span>
                                    </Link>
                                </Menu.Item>)}
                            <Menu.Item key="company/rates">
                                <Link to="/company/rates">
                                    <span className="pl-7 text-gray-200 text-sm">Rates</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="company/guides">
                                <a download href="/User Guide - Home and Company Tab (Payroll Module).pdf">
                                    <span className="pl-7 text-gray-200 text-sm">Company Guide</span>
                                </a>
                            </Menu.Item>

                        </SubMenu>)}
                    {(Helpers.authorizeMe(authorizations[1], 'view', 3)
                    ||
                    Helpers.authorizeMe(authorizations[1], 'view', 4)
                        ||
                    Helpers.authorizeMe(authorizations[1], 'view', 32))
                    && (
                        <SubMenu key="employee"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaUsers/>
                                     </div>
                                     <div className="px-6 -my-3">Employees</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[1], 'view', 3) && (
                                <Menu.Item key="employee/employee-center">
                                    <Link to="/employee/employee-center">

                                        <span className="pl-7 text-gray-200 text-sm">Employee Center</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[1], 'view', 4) && (
                                <Menu.Item key="employee/employee-variations">
                                    <Link to="/employee/employee-variations">

                                        <span className="pl-7 text-gray-200 text-sm">Employee Variations</span>
                                    </Link>
                                </Menu.Item>)}
                                {Helpers.authorizeMe(authorizations[1], 'view', 32) && (
                                <Menu.Item key="employee/employee-earnings">
                                    <Link to="/employee/employee-earnings">

                                        <span className="pl-7 text-gray-200 text-sm">Earnings</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[1], 'view', 32) && (
                                <Menu.Item key="employee/employee-grades">
                                    <Link to="/employee/employee-grades">

                                        <span className="pl-7 text-gray-200 text-sm">Employee Grades</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[1], 'view', 32) && (
                                <Menu.Item key="employee/employee-rates">
                                    <Link to="/employee/employee-rates">

                                        <span className="pl-7 text-gray-200 text-sm">Employee Rates</span>
                                    </Link>
                                </Menu.Item>)}
                            <Menu.Item key="employee/guides">
                                <a download href="/User Guide - Employees Tab (Payroll Module).pdf">
                                    <span className="pl-7 text-gray-200 text-sm">Employees Guide</span>
                                </a>
                            </Menu.Item>

                        </SubMenu>)}
                    {(Helpers.authorizeMe(authorizations[1], 'view', 5)
                    ||
                    Helpers.authorizeMe(authorizations[1], 'view', 6)
                    ||
                    Helpers.authorizeMe(authorizations[1], 'view', 7)
                    ||
                    Helpers.authorizeMe(authorizations[1], 'view', 8)
                    ||
                    Helpers.authorizeMe(authorizations[1], 'view', 9)
                        ||
                    Helpers.authorizeMe(authorizations[1], 'view', 31))
                    && (
                        <SubMenu key="payroll"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaBook/>
                                     </div>
                                     <div className="px-8 -my-3">Payroll</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[1], 'view', 5) && (
                                <Menu.Item key="payroll/payroll-center">
                                    <Link to="/payroll/payroll-center">

                                        <span className="pl-7 text-gray-200 text-sm">Payroll Center</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[1], 'view', 6) && (
                                <Menu.Item key="payroll/payroll-items">
                                    <Link to="/payroll/payroll-items">
                                        <span className="pl-7 text-gray-200 text-sm">Payroll Items</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[1], 'view', 7) && (
                                <Menu.Item key="payroll/paychecks">
                                    <Link to="/payroll/paychecks">
                                        <span className="pl-7 text-gray-200 text-sm">Paychecks</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[1], 'view', 8) && (
                                <Menu.Item key="payroll/payroll-setup">
                                    <Link to="/payroll/payroll-setup">
                                        <span className="pl-7 text-gray-200 text-sm">Payroll Setup</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[1], 'view', 9) && (
                                <Menu.Item key="payroll/payroll-reports">
                                    <Link to="/payroll/payroll-reports">
                                        <span className="pl-7 text-gray-200 text-sm">Payroll Reports</span>
                                    </Link>
                                </Menu.Item>)}
                                {Helpers.authorizeMe(authorizations[1], 'view', 9) && (
                                <Menu.Item key="payroll/legacy-reports">
                                    <Link to="/payroll/legacy-reports">
                                        <span className="pl-7 text-gray-200 text-sm">Legacy Reports</span>
                                    </Link>
                                </Menu.Item>)}
                                {Helpers.authorizeMe(authorizations[1], 'view', 31) && (
                                <Menu.Item key="payroll/payroll-warehousing">
                                    <Link to="/payroll/payroll-warehousing">
                                        <span className="pl-7 text-gray-200 text-sm">Warehousing</span>
                                    </Link>
                                </Menu.Item>)}
                            <Menu.Item key="payroll/guides">
                                <a download href="/User Guide - Payroll Tab (Payroll Module).pdf">
                                    <span className="pl-7 text-gray-200 text-sm">Payroll Guide</span>
                                </a>
                            </Menu.Item>
                        </SubMenu>)}

                </MenuItemGroup>

            </Menu>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

